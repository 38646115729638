import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="container z-0 grid grid-cols-9 grid-rows-4 gap-4 px-5 lg:px-0 py-3 justify-center content-center">
      <StaticImage
        className="col-span-5 row-span-4"
        src="../images/top/top-1.webp"
      />
      <StaticImage
        className="row-span-2 col-span-2"
        src="../images/top/top-2.webp"
      />
      <StaticImage
        className="row-span-2 col-span-2"
        src="../images/top/top-3.webp"
      />
      <StaticImage
        className="row-span-2 col-span-2"
        src="../images/top/top-4.webp"
      />
      <StaticImage
        className="row-span-2 col-span-2"
        src="../images/top/top-5.webp"
      />
    </div>
    
    <div
      className="px-10 py-5 divide-y space-y-12"
    >
      <div>
        <p className="text-textblack text-2xl sm:text-3xl text-center">思い出の写真は「写真館の写真で」</p>
        <p className="text-textblack text-center my-3 leading-7">
          アライヤ写真館は昭和28年創業、お客様の人生の節目等、さまざまなシーンを撮り続けてきました。<br />
          当館は、お客様の声を大事に一人一人丁寧に対応していきます。<br />
          昔ながらの写真館ではありますが、伝統と技術、そして笑いのある撮影現場で、お客様に「またお願いしたい」と
          満足いただけるお写真の提供を常に心がけています。
        </p>
        <p className="text-textblack text-xl text-center">写真の仕上がりはキレイに、お客様を美しく。</p>
      </div>
      <div className="pt-6 justify-center">
        <p className="text-textblack text-2xl sm:text-3xl text-center">撮影メニュー</p>
        <div className="mt-1 container mx-auto lg:w-1/2  max-w-md z-0 h-60 grid grid-cols-2 grid-rows-2 gap-5 justify-center content-center">
          <div className="place-self-center">
            <Link to="studiophoto">
              <StaticImage
                className=""
                src="../images/studiophoto.webp"
              />
              <p className="text-textblack text-center">スタジオ写真</p>
            </Link>
          </div>
          <div className="place-self-center">
            <Link to="idphoto">
              <StaticImage
                className=""
                src="../images/idphoto.webp"
              />
              <p className="text-textblack text-center">証明写真</p>
            </Link>
          </div>
          <div className="place-self-center">
            <Link to="print">
              <StaticImage
                className=""
                src="../images/dpe.webp"
              />
              <p className="text-textblack text-center">デジタルプリント</p>
            </Link>
          </div>
          <div className="place-self-center">
            <Link to="/retouch">
              <StaticImage
                className="object-center"
                src="../images/retouch.webp"
              />
              <p className="text-textblack text-center">古いお写真の修整</p>
            </Link>
          </div>
        </div>
      </div>
      <div className="pt-6 container mx-auto grid grid-cols-1 grid-rows-3 lg:grid-cols-3 lg:grid-rows-1">
        <div className="justify-center space-y-2">
          <p className="text-textblack text-2xl sm:text-3xl">アクセス</p>
          <p className="text-textblack">
            〒361-0077 埼玉県行田市忍1-6-6<br />
            行田市商工センターそば<br />
            ※店舗向かいに駐車場4～5台有り <br />
          </p>
        </div>
        <div className="justify-center space-y-2" id="contact">
          <p className="text-textblack text-2xl sm:text-3xl">営業時間</p>
          <p className="text-textblack">
            10:00～18:00 不定休<br />
            (出張撮影時等 休館)
          </p>
        </div>
        <div className="justify-center space-y-2">
          <p className="text-textblack text-2xl sm:text-3xl">ご予約・お問い合わせ</p>
          <p className="text-textblack text-2xl text-center">TEL: 048-556-2921</p>
          <p className="text-textblack">お急ぎの場合は、お電話にてお問い合わせください。</p>
          <p className="text-textblack text-sm">但し、撮影などでお電話に出られない時があります。</p>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLScDEmvKHOOuVDi5ny-S9W6V7Dv0Gn6S2905HgH3pV9PKd2q7Q/viewform" target="_blank" rel="noopener noreferrer">お問合せフォーム</a>
          <p className="text-textblack text-sm">
            <a href="https://g.page/r/CRzuTSF6tT8iEAE" target="_blank" rel="noopener noreferrer">Googleマップのチャット機能</a>
            でも受け付けております。
          </p>
          
          
        </div>
      </div>
      <div className="container h-40">
        <iframe className="w-full h-full" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3222.0712190014133!2d139.45585804893494!3d36.14047916162075!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x223fb57a214dee1c!2z44Ki44Op44Kk44Ok5YaZ55yf6aSo!5e0!3m2!1sja!2sjp!4v1645465272796!5m2!1sja!2sjp" height="450" allowfullscreen="" loading="lazy"></iframe>
      </div>
        
    </div>
  </Layout>
)

export default IndexPage
